import { render, staticRenderFns } from "./WellsCrossSection.vue?vue&type=template&id=2cd28966"
import script from "./WellsCrossSection.js?vue&type=script&lang=js&external"
export * from "./WellsCrossSection.js?vue&type=script&lang=js&external"
import style0 from "./WellsCrossSection.vue?vue&type=style&index=0&id=2cd28966&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports